import React from 'react';
import { Pane, Menu } from 'evergreen-ui';
import { NavDropdownLink, DropdownNav } from '.'
import { platformsSplit, platformMap } from '../../utils/constants';
// const { versusSlugs, alternativesSlugs } = require('../../../pages-info');

const RatesVsRatesNav = () => {
    const popoverContent = (
        <Pane maxHeight="80vh" overflow="auto" display="flex">
            {platformsSplit.map((column) => (
                <Menu>
                    <Menu.Group>
                        {column.map((platform) => {
                            const { versusPath, name, imageUrl } = platformMap[platform];
                            return <NavDropdownLink slug={versusPath} imageUrl={imageUrl} label={`${name} Rates vs`} />
                        })}
                    </Menu.Group>
                </Menu>
            ))}
        </Pane>
    );

    return <DropdownNav popoverContent={popoverContent}>Platform vs Platform</DropdownNav>;
};

export default RatesVsRatesNav;
