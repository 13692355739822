export const currentMonth = 'August';
export const currentMonthShort = 'Aug';

export const slugBTC = '/bitcoin-btc-interest/';
export const slugETH = '/ethereum-eth-interest/';
export const slugUSDT = '/tether-usdt-interest/';
export const slugUSDC = '/usd-coin-usdc-interest/';
export const slugDAI = '/dai-interest/';
export const slugGUSD = '/gemini-dollar-gusd-interest/';
export const slugLTC = '/litecoin-ltc-interest/';
export const slugBCH = '/bitcoin-cash-bch-interest/';
export const slugLINK = '/chainlink-link-interest/';
export const slugDOT = '/polkadot-dot-interest/';
export const slugXLM = '/stellar-lumens-xlm-interest/';
export const slugMATIC = '/polygon-matic-interest/';
export const slugMANA = '/decentraland-mana-interest/';
export const slugAAVE = '/aave-interest/';
export const slugBAT = '/basic-attention-token-bat-interest/';
export const slugUNI = '/uniswap-uni-interest/';
export const slugDOGE = '/dogecoin-doge-interest/';
export const slugADA = '/cardano-ada-interest/';
export const slugEOS = '/eosio-eos-interest/';
export const slugXTZ = '/tezos-xtz-interest/';
export const slugGRT = '/the-graph-grt-interest/';
export const slugCOMP = '/compound-comp-interest/';
export const slugMKR = '/maker-mkr-interest/';
export const slugPAXG = '/pax-gold-paxg-interest/';
export const slugXRP = '/ripple-xrp-interest/';
export const slugZRX = '/0x-zrx-interest/';
export const slugBUSD = '/binance-usd-busd-interest/';
export const slugLUNA = '/terra-luna-interest/';
export const slugSOL = '/solana-sol-interest/';
export const slugSUSHI = '/sushiswap-sushi-interest/';
export const slugZEC = '/zcash-zec-interest/';
export const slugSNX = '/synthetix-snx-interest/';
export const slug1INCH = '/1inch-network-interest/';
export const slugTUSD = '/trueusd-tusd-interest/';
export const slugUMA = '/universal-market-access-uma-interest/';
export const slugKNC = '/kyber-network-crystal-knc-interest/';
export const slugAVAX = '/avalanche-avax-interest/';
export const slugOMG = '/omg-network-interest/';
export const slugSHIB = '/shiba-inu-shib-interest/';
export const slugBNB = '/binance-coin-bnb-interest/';
export const slugETC = '/ethereum-classic-etc-interest/';
export const slugBNT = '/bancor-bnt-interest/';
export const slugATOM = '/cosmos-atom-interest/';
export const slugOXT = '/orchid-oxt-interest/';
export const slugFIL = '/filecoin-fil-interest/';
export const slugFTM = '/fantom-ftm-interest/';
export const slugAXS = '/axie-infinity-axs-interest/';
export const slugUSDP = '/pax-dollar-usdp-interest/';
export const slugUST = '/terra-usd-ust-interest/';
export const slugAPE = '/apecoin-ape-interest/';
export const slugNEAR = '/near-protocol-interest/';
export const slugCRV = '/curve-crv-interest/';
export const slugYFI = '/yearn-finance-yfi-interest/';
export const slugALGO = '/algorand-algo-interest/';
export const slugWBTC = '/wrapped-bitcoin-wbtc-interest/';
export const slugAMP = '/amp-interest/';
export const slugFLOW = '/flow-interest/';
export const slugCELR = '/celer-network-celr-interest/';
export const slugANKR = '/ankr-interest/';
export const slugBAL = '/balancer-bal-interest/';
export const slugTRX = '/tron-trx-interest/';
export const slugEGLD = '/elrond-egold-egld-interest/';
export const slugENJ = '/enjin-coin-enj-interest/';
export const slugLPT = '/livepeer-lpt-interest/';
export const slugSTORJ = '/storj-interest/';
export const slugINJ = '/injective-inj-interest/';
export const slugUSDD = '/decentralized-usd-usdd-interest/';
export const slugSLP = '/smooth-love-potion-slp-interest/';
export const slugONE = '/harmony-one-interest/';
export const slugZIL = '/zilliqa-zil-interest/';
export const slugVET = '/vechain-vet-interest/';
export const slugRON = '/ronin-ron-interest/';
export const slugCRO = '/cronos-cro-interest/';
// export const slugXSGD = '/straitsx-singapore-dollar-xsgd-interest/';
// export const slugXIDR = '/straitsx-indonesian-rupiah-xidr-interest/';

export const slugBlockfiRates = '/blockfi-rates/';
export const slugVoyagerRates = '/voyager-rates/';
export const slugGeminiRates = '/gemini-earn-rates/';
export const slugCelsiusRates = '/celsius-rates/';
export const slugHodlnautRates = '/hodlnaut-rates/';
export const slugLednRates = '/ledn-rates/';
export const slugCryptoComRates = '/crypto-com-app-rates/';
export const slugNexoRates = '/nexo-rates/';
export const slugVauldRates = '/vauld-rates/';
export const slugHaruRates = '/haru-invest-rates/';
export const slugAbraRates = '/abra-rates/';
export const slugFinbloxRates = '/finblox-rates/';

export const cryptoCurrencies = [
    'BTC',
    'ETH',
    'USDT',
    'USDC',
    // 'GUSD',
    'DAI',
    // 'BUSD',
    'MATIC',
    'DOT',
    'SOL',
    'ADA',
    'PAXG',
    'LTC',
    'BCH',
    'LINK',
    'XRP',
    'AVAX',
    'BNB',
    'DOGE',
    'FTM',
    'AXS',
    // 'MANA',
    'TUSD',
    // 'BAT',
    'ATOM',
    'XLM',
    'USDP',
    'AAVE',
    'SHIB',
    'ALGO',
    'NEAR',
    // 'UNI',
    'APE',
    // 'ETC',
    // 'SNX',
    // 'FIL',
    // 'ZEC',
    'GRT',
    // 'SUSHI',
    'EOS',
    // 'XTZ',
    // 'CRV',
    // 'MKR',
    // '1INCH',
    // 'COMP',
    // 'BNT',
    // 'OXT',
    // 'OMG',
    // 'UMA',
    // 'YFI',
    // 'ZRX',
    // 'KNC',
    // 'WBTC',
    // 'AMP',
    // 'FLOW',
    // 'CELR',
    // 'ANKR',
    // 'BAL',
    'TRX',
    'EGLD',
    // 'USDD',
    // 'SLP',
    'ONE',
    'ZIL',
    'VET',
    // 'RON',
    'CRO',
    // 'XSGD',
    // 'XIDR',
    // 'ENJ',
    // 'LPT',
    // 'STORJ',
    // 'INJ',
];

export const highestRates = [
    { name: 'All', slug: '/highest-rates/', imageUrl: 'https://res.cloudinary.com/dtujxzpfy/image/upload/v1650251081/blog-images/highest-crypto-rates-featured-image.png' },
    { name: 'Stablecoins', slug: '/highest-rates/stablecoins/', imageUrl: 'https://res.cloudinary.com/dtujxzpfy/image/upload/v1650215290/blog-images/highest-stablecoin-rates-featured-image.png' },
    { name: 'Cryptocurrencies', slug: '/highest-rates/cryptocurrencies/', imageUrl: 'https://res.cloudinary.com/dtujxzpfy/image/upload/v1650251063/blog-images/highest-cryptocurrency-rates-featured-image.png' },
];

const slugBlockfiWithdrawalFees = 'blockfi-withdrawal-fees';
const slugVoyagerWithdrawalFees = 'voyager-withdrawal-fees';
const slugGeminiWithdrawalFees = 'gemini-earn-withdrawal-fees';
const slugCelsiusWithdrawalFees = 'celsius-withdrawal-fees';
const slugHodlnautWithdrawalFees = 'hodlnaut-withdrawal-fees';
const slugLednWithdrawalFees = 'ledn-withdrawal-fees';
const slugCryptoComWithdrawalFees = 'crypto-com-earn-withdrawal-fees';
const slugNexoWithdrawalFees = 'nexo-withdrawal-fees';
const slugVauldWithdrawalFees = 'vauld-withdrawal-fees';
const slugHaruWithdrawalFees = 'haru-invest-withdrawal-fees';
const slugAbraWithdrawalFees = 'abra-withdrawal-fees';
const slugFinbloxWithdrawalFees = 'finblox-withdrawal-fees';

export const platformMap = {
    celsius: {
        slug: slugCelsiusRates,
        name: 'Celsius',
        imageUrl: '/images/celsius-logo.svg',
        versusPath: '/celsius-vs-alternatives/',
        withdrawalSlug: slugCelsiusWithdrawalFees,
        featuredImage: 'https://res-3.cloudinary.com/dtujxzpfy/image/upload/c_scale,h_300,q_auto/v1/blog-images/celsius-logo-referral-code-sign-up-bonus.jpg',
    },
    blockfi: {
        slug: slugBlockfiRates,
        name: 'BlockFi',
        imageUrl: '/images/blockfi-logo.svg',
        versusPath: '/blockfi-vs-alternatives/',
        withdrawalSlug: slugBlockfiWithdrawalFees,
        featuredImage: 'https://res-2.cloudinary.com/dtujxzpfy/image/upload/c_scale,h_300,q_auto/v1/blog-images/blockfi-logo-referral-code-sign-up-bonus.png',
    },
    gemini: {
        slug: slugGeminiRates,
        name: 'Gemini Earn',
        imageUrl: '/images/gemini-logo.svg',
        versusPath: '/gemini-earn-vs-alternatives/',
        withdrawalSlug: slugGeminiWithdrawalFees,
        featuredImage: 'https://res-3.cloudinary.com/dtujxzpfy/image/upload/c_scale,h_300,q_auto/v1/blog-images/gemini-logo-referral-code-sign-up-bonus.jpg',
    },
    voyager: {
        slug: slugVoyagerRates,
        name: 'Voyager',
        imageUrl: '/images/voyager-logo.png',
        versusPath: '/voyager-vs-alternatives/',
        withdrawalSlug: slugVoyagerWithdrawalFees,
        featuredImage: 'https://res-2.cloudinary.com/dtujxzpfy/image/upload/c_scale,h_300,q_auto/v1/blog-images/voyager-logo-referral-code-sign-up-bonus.jpg',
    },
    hodlnaut: {
        slug: slugHodlnautRates,
        name: 'Hodlnaut',
        imageUrl: '/images/hodlnaut-logo.svg',
        versusPath: '/hodlnaut-vs-alternatives/',
        withdrawalSlug: slugHodlnautWithdrawalFees,
        featuredImage: 'https://res-3.cloudinary.com/dtujxzpfy/image/upload/c_scale,h_300,q_auto/v1/blog-images/hodlnaut-logo-referral-code-sign-up-bonus.png',
    },
    ledn: {
        slug: slugLednRates,
        name: 'Ledn',
        imageUrl: '/images/ledn-logo.jpeg',
        versusPath: '/ledn-vs-alternatives/',
        withdrawalSlug: slugLednWithdrawalFees,
        featuredImage: 'https://res-5.cloudinary.com/dtujxzpfy/image/upload/c_scale,h_300,q_auto/v1/blog-images/Ledn-logo-referral-code-sign-up-bonus.png',
    },
    vauld: {
        slug: slugVauldRates,
        name: 'Vauld',
        imageUrl: '/images/vauld-logo.svg',
        versusPath: '/vauld-vs-alternatives/',
        withdrawalSlug: slugVauldWithdrawalFees,
        featuredImage: 'https://res-4.cloudinary.com/dtujxzpfy/image/upload/c_scale,h_300,q_auto/v1/blog-images/vauld-logo-referral-code-sign-up-bonus.png',
    },
    cryptoCom: {
        slug: slugCryptoComRates,
        name: 'Crypto.com',
        imageUrl: '/images/crypto-com-logo.svg',
        versusPath: '/crypto-com-app-vs-alternatives/',
        withdrawalSlug: slugCryptoComWithdrawalFees,
        featuredImage: 'https://res-5.cloudinary.com/dtujxzpfy/image/upload/c_scale,h_300,q_auto/v1/blog-images/crypto-com-app-referral-code-sign-up-bonus.png',
    },
    nexo: {
        slug: slugNexoRates,
        name: 'Nexo',
        imageUrl: '/images/nexo-logo.svg',
        versusPath: '/nexo-vs-alternatives/',
        withdrawalSlug: slugNexoWithdrawalFees,
        featuredImage: 'https://res-1.cloudinary.com/dtujxzpfy/image/upload/c_scale,h_300,q_auto/v1/blog-images/nexo-logo-referral-code-sign-up-bonus.png',
    },
    haru: {
        slug: slugHaruRates,
        name: 'Haru Invest',
        imageUrl: '/images/haru-logo.svg',
        versusPath: '/haru-invest-vs-alternatives/',
        withdrawalSlug: slugHaruWithdrawalFees,
        featuredImage: 'https://res.cloudinary.com/dtujxzpfy/image/upload/c_scale,h_300,q_auto/v1644104258/blog-images/haru-logo-referral-sign-up-bonus_o.png',
    },
    abra: {
        slug: slugAbraRates,
        name: 'Abra',
        imageUrl: '/images/abra-logo.png',
        versusPath: '/abra-vs-alternatives/',
        withdrawalSlug: slugAbraWithdrawalFees,
        featuredImage: 'https://res-1.cloudinary.com/dtujxzpfy/image/upload/c_scale,h_300,q_auto/v1/blog-images/abra-logo-referral-code-sign-up-bonus.png',
    },
    finblox: {
        slug: slugFinbloxRates,
        name: 'Finblox',
        imageUrl: '/images/finblox-logo.png',
        versusPath: '/finblox-vs-alternatives/',
        withdrawalSlug: slugFinbloxWithdrawalFees,
        featuredImage: 'https://res-1.cloudinary.com/dtujxzpfy/image/upload/c_scale,h_300,q_auto/v1/blog-images/finblox-logo-referral-code-sign-up-bonus.png',
    },
};

const { celsius, voyager, vauld, hodlnaut, blockfi, gemini, haru, abra, ...activePlatforms } = platformMap;
export const platforms = Object.keys(activePlatforms);
export const platformsSplit = [[], []];
for (let i = 0; i < platforms.length; i++){
    if ((i + 2) % 2 == 0) {
        platformsSplit[0].push(platforms[i]);
    } else {
        platformsSplit[1].push(platforms[i]);
    }
}

export const currencies3Split = [[], [], []];
for (let i = 0; i < cryptoCurrencies.length; i++){
    if ((i + 3) % 3 == 0) {
        currencies3Split[0].push(cryptoCurrencies[i]);
    } else if ((i + 3) % 3 == 1) {
        currencies3Split[1].push(cryptoCurrencies[i]);
    } else {
        currencies3Split[2].push(cryptoCurrencies[i]);
    }
}

export const currencies4Split = [[], [], [], []];
for (let i = 0; i < cryptoCurrencies.length; i++){
    if ((i + 4) % 4 == 0) {
        currencies4Split[0].push(cryptoCurrencies[i]);
    } else if ((i + 4) % 4 == 1) {
        currencies4Split[1].push(cryptoCurrencies[i]);
    } else if ((i + 4) % 4 == 2) {
        currencies4Split[2].push(cryptoCurrencies[i]);
    } else {
        currencies4Split[3].push(cryptoCurrencies[i]);
    }
}


export const symbolToCurrencyMap = {
    BTC: { symbol: 'BTC', name: 'Bitcoin', slug: slugBTC, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/1.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1.png' },
    ETH: { symbol: 'ETH', name: 'Ethereum', slug: slugETH, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/1027.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png' },
    USDT: { symbol: 'USDT', name: 'Tether', slug: slugUSDT, isStablecoin: true, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/825.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png' },
    USDC: { symbol: 'USDC', name: 'USD Coin', slug: slugUSDC, isStablecoin: true, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/3408.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png' },
    DAI: { symbol: 'DAI', name: 'Dai', slug: slugDAI, isStablecoin: true, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/4943.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png' },
    GUSD: { symbol: 'GUSD', name: 'Gemini Dollar', slug: slugGUSD, isStablecoin: true, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/3306.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3306.png' },
    LTC: { symbol: 'LTC', name: 'Litecoin', slug: slugLTC, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/2.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2.png' },
    BCH: { symbol: 'BCH', name: 'Bitcoin Cash', slug: slugBCH, largeImage: 'https://assets.coingecko.com/coins/images/780/large/bitcoin-cash-circle.png', imageUrl: 'https://assets.coingecko.com/coins/images/780/small/bitcoin-cash-circle.png' },
    LINK: { symbol: 'LINK', name: 'Chainlink', slug: slugLINK, largeImage: 'https://assets.coingecko.com/coins/images/877/large/chainlink-new-logo.png', imageUrl: 'https://assets.coingecko.com/coins/images/877/small/chainlink-new-logo.png' },
    DOT: { symbol: 'DOT', name: 'Polkadot', slug: slugDOT, largeImage: 'https://assets.coingecko.com/coins/images/12171/large/polkadot.png', imageUrl: 'https://assets.coingecko.com/coins/images/12171/small/polkadot.png' },
    XLM: { symbol: 'XLM', name: 'Stellar Lumens', slug: slugXLM, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/512.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/512.png' },
    MATIC: { symbol: 'MATIC', name: 'Polygon', slug: slugMATIC, largeImage: 'https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png', imageUrl: 'https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png' },
    MANA: { symbol: 'MANA', name: 'Decentraland', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/1966.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1966.png' },
    AAVE: { symbol: 'AAVE', name: 'Aave', slug: slugAAVE, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/7278.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7278.png' },
    DOGE: { symbol: 'DOGE', name: 'Dogecoin', slug: slugDOGE, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/74.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/74.png' },
    ADA: { symbol: 'ADA', name: 'Cardano', slug: slugADA, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/2010.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2010.png' },
    PAX: { symbol: 'PAX', name: 'Paxos Standard', slug: slugUSDP, isStablecoin: true, imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3330.png' },
    PAXG: { symbol: 'PAXG', name: 'PAX Gold', slug: slugPAXG, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/4705.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4705.png' },
    BUSD: { symbol: 'BUSD', name: 'Binance USD', isStablecoin: true, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/4687.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png' },
    DASH: { symbol: 'DASH', name: 'Dash', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/131.png' },
    VGX: { symbol: 'VGX', name: 'Voyager Token', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1817.png' },
    BSV: { symbol: 'BSV', name: 'Bitcoin SV', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3602.png' },
    ATOM: { symbol: 'ATOM', name: 'Cosmos', slug: slugATOM, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/3794.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3794.png' },
    CELO: { symbol: 'CELO', name: 'Celo', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5567.png' },
    COMP: { symbol: 'COMP', name: 'Compound', slug: slugCOMP, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/5692.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5692.png' },
    SHIB: { symbol: 'SHIB', name: 'Shiba Inu', slug: slugSHIB, largeImage: 'https://assets.coingecko.com/coins/images/11939/large/shiba.png', imageUrl: 'https://assets.coingecko.com/coins/images/11939/small/shiba.png' },
    EOS: { symbol: 'EOS', name: 'EOSIO', slug: slugEOS, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/1765.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1765.png' },
    ETC: { symbol: 'ETC', name: 'Ethereum Classic', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/1321.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1321.png' },
    UMA: { symbol: 'UMA', name: 'Universal Market Access', slug: slugUMA, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/5617.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5617.png' },
    UNI: { symbol: 'UNI', name: 'Uniswap', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/7083.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7083.png' },
    ZRX: { symbol: 'ZRX', name: '0x', slug: slugZRX, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/1896.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1896.png' },
    XTZ: { symbol: 'XTZ', name: 'Tezos', slug: slugXTZ, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/2011.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2011.png' },
    OXT: { symbol: 'OXT', name: 'Orchid', slug: slugOXT, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/128x128/5026.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5026.png' },
    BAT: { symbol: 'BAT', name: 'Basic Attention Token', largeImage: 'https://assets.coingecko.com/coins/images/677/large/basic-attention-token.png', imageUrl: 'https://assets.coingecko.com/coins/images/677/small/basic-attention-token.png' },
    FIL: { symbol: 'FIL', name: 'Filecoin', slug: slugFIL, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/2280.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2280.png' },
    GRT: { symbol: 'GRT', name: 'The Graph', slug: slugGRT, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/6719.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6719.png' },
    KNC: { symbol: 'KNC', name: 'Kyber Network Crystal', slug: slugKNC, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/9444.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/9444.png' },
    LUNA: { symbol: 'LUNA', name: 'Terra', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/4172.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4172.png' },
    OMG: { symbol: 'OMG', name: 'OMG Network', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/1808.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1808.png' },
    ZEC: { symbol: 'ZEC', name: 'Zcash', slug: slugZEC, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/1437.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1437.png' },
    STMX: { symbol: 'STMX', name: 'StormX', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2297.png' },
    '1INCH': { symbol: '1INCH', name: '1inch Network', slug: slug1INCH, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/8104.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8104.png' },
    ALCX: { symbol: 'ALCX', name: 'Alchemix', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8613.png' },
    AMP: { symbol: 'AMP', name: 'Amp Token', slug: slugAMP, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/6945.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6945.png' },
    ANKR: { symbol: 'ANKR', name: 'Ankr', slug: slugANKR, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/3783.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3783.png' },
    AXS: { symbol: 'AXS', name: 'Axie Infinity Shards', slug: slugAXS, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/6783.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6783.png' },
    BAL: { symbol: 'BAL', name: 'Balancer', slug: slugBAL, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/5728.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5728.png' },
    CRV: { symbol: 'CRV', name: 'Curve DAO', slug: slugCRV, largeImage: 'https://assets.coingecko.com/coins/images/12124/large/Curve.png', imageUrl: 'https://assets.coingecko.com/coins/images/12124/small/Curve.png' },
    FTM: { symbol: 'FTM', name: 'Fantom', slug: slugFTM, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/3513.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png' },
    INJ: { symbol: 'INJ', name: 'Injective', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/7226.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7226.png' },
    LPT: { symbol: 'LPT', name: 'Livepeer', slug: slugLPT, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/3640.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3640.png' },
    MIR: { symbol: 'MIR', name: 'Mirror', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7857.png' },
    MKR: { symbol: 'MKR', name: 'Maker', slug: slugMKR, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/1518.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1518.png' },
    REN: { symbol: 'REN', name: 'Ren', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2539.png' },
    SNX: { symbol: 'SNX', name: 'Synthetix', slug: slugSNX, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/2586.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2586.png' },
    STORJ: { symbol: 'STORJ', name: 'Storj DCS', slug: slugSTORJ, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/1772.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1772.png' },
    SUSHI: { symbol: 'SUSHI', name: 'SushiSwap', slug: slugSUSHI, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/6758.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6758.png' },
    UST: { symbol: 'UST', name: 'TerraUSD', isStablecoin: true, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/7129.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7129.png' },
    YFI: { symbol: 'YFI', name: 'Yearn Finance', slug: slugYFI, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/5864.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5864.png' },
    BNB: { symbol: 'BNB', name: 'Binance Coin', slug: slugBNB, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/1839.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png' },
    BNT: { symbol: 'BNT', name: 'Bancor', slug: slugBNT, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/1727.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1727.png' },
    CEL: { symbol: 'CEL', name: 'Celsius', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/128x128/2700.png' },
    TAUD: { symbol: 'TAUD', name: 'TrueAUD', isStablecoin: true, imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7354.png' },
    TCAD: { symbol: 'TCAD', name: 'TrueCAD', isStablecoin: true, imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7339.png' },
    TGBP: { symbol: 'TGBP', name: 'TrueGBP', isStablecoin: true, imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7340.png' },
    THKD: { symbol: 'THKD', name: 'TrueHKD', isStablecoin: true, imageUrl: 'https://assets.coingecko.com/coins/images/9465/small/THKD.png?1567642964' },
    TUSD: { symbol: 'TUSD', name: 'TrueUSD', isStablecoin: true, slug: slugTUSD, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/2563.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2563.png' },
    WDGLD: { symbol: 'WDGLD', name: 'Wrapped-DGLD', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/128x128/5119.png' },
    XAUT: { symbol: 'XAUT', name: 'Tether Gold', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/128x128/5176.png' },
    XRP: { symbol: 'XRP', name: 'Ripple', slug: slugXRP, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/52.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/52.png' },
    ZUSD: { symbol: 'ZUSD', name: 'Z.com USD', isStablecoin: true, imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/128x128/8772.png' },
    WBTC: { symbol: 'WBTC', name: 'Wrapped Bitcoin', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/3717.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png' },
    SOL: { symbol: 'SOL', name: 'Solana', slug: slugSOL, largeImage: 'https://assets.coingecko.com/coins/images/4128/large/solana.png', imageUrl: 'https://assets.coingecko.com/coins/images/4128/small/solana.png' },
    AVAX: { symbol: 'AVAX', name: 'Avalanche', slug: slugAVAX, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/5805.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png' },
    ONE: { symbol: 'ONE', name: 'Harmony', slug: slugONE, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/3945.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3945.png' },
    FLOW: { symbol: 'FLOW', name: 'Flow', largeImage: 'https://assets.coingecko.com/coins/images/13446/large/5f6294c0c7a8cda55cb1c936_Flow_Wordmark.png', imageUrl: 'https://assets.coingecko.com/coins/images/13446/small/5f6294c0c7a8cda55cb1c936_Flow_Wordmark.png' },
    STRAX: { symbol: 'STRAX', name: 'Stratis', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1343.png' },
    ONG: { symbol: 'ONG', name: 'Ontology Gas', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3217.png' },
    CELR: { symbol: 'CELR', name: 'Celer Network', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/3814.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3814.png' },
    ICX: { symbol: 'ICX', name: 'Icon', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2099.png' },
    ENJ: { symbol: 'ENJ', name: 'Enjin Coin', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/2130.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2130.png' },
    CRO: { symbol: 'CRO', name: 'Cronos', slug: slugCRO, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/3635.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3635.png' },
    ALGO: { symbol: 'ALGO', name: 'Algorand', slug: slugALGO, largeImage: 'https://assets.coingecko.com/coins/images/4380/large/download.png', imageUrl: 'https://assets.coingecko.com/coins/images/4380/small/download.png' },
    VET: { symbol: 'VET', name: 'VeChain', slug: slugVET, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/3077.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3077.png' },
    EGLD: { symbol: 'EGLD', name: 'MultiversX', slug: slugEGLD, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/6892.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6892.png' },
    GAS: { symbol: 'GAS', name: 'Gas', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1785.png' },
    USDP: { symbol: 'USDP', name: 'Pax Dollar', slug: slugUSDP, isStablecoin: true, largeImage: 'https://assets.coingecko.com/coins/images/6013/large/Pax_Dollar.png', imageUrl: 'https://assets.coingecko.com/coins/images/6013/small/Pax_Dollar.png' },
    USDx: { symbol: 'USDx', name: 'USDx', isStablecoin: true, imageUrl: 'https://content.nexo.com/logos/currency-usd-flag.svg' },
    EURx: { symbol: 'EURx', name: 'EURx', isStablecoin: true, imageUrl: 'https://content.nexo.com/logos/currency-eur-flag.svg' },
    GBPx: { symbol: 'GBPx', name: 'GBPx', isStablecoin: true, imageUrl: 'https://content.nexo.com/logos/currency-gbp-flag.svg' },
    NEXO: { symbol: 'NEXO', name: 'Nexo', imageUrl: 'https://content.nexo.com/logos/currency-nexo.svg' },
    TRX: { symbol: 'TRX', name: 'TRON', slug: slugTRX, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/1958.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png' },
    FTT: { symbol: 'FTT', name: 'FTX Token', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4195.png' },
    VVS: { symbol: 'VVS', name: 'VVS Finance', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/14519.png' },
    IOST: { symbol: 'IOST', name: 'Internet of Services Token', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2405.png' },
    PERP: { symbol: 'PERP', name: 'Perpetual Protocol', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6950.png' },
    ALICE: { symbol: 'ALICE', name: 'My Neighbor Alice', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8766.png' },
    ALPHA: { symbol: 'ALPHA', name: 'Alpha Finance Lab', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7232.png' },
    ATA: { symbol: 'ATA', name: 'Automata Network', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10188.png' },
    AVA: { symbol: 'AVA', name: 'Travala.com', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2776.png' },
    BAKE: { symbol: 'BAKE', name: 'BakeryToken', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7064.png' },
    BAND: { symbol: 'BAND', name: 'Band Protocol', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4679.png' },
    BTCST: { symbol: 'BTCST', name: 'Bitcoin Standard Hashrate Token', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8891.png' },
    CFX: { symbol: 'CFX', name: 'Conflux', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7334.png' },
    CHR: { symbol: 'CHR', name: 'Chromia', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3978.png' },
    COS: { symbol: 'COS', name: 'Contentos', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4036.png' },
    COTI: { symbol: 'COTI', name: 'COTI', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3992.png' },
    CTSI: { symbol: 'CTSI', name: 'Cartesi', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5444.png' },
    DUSK: { symbol: 'DUSK', name: 'Dusk Network', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4092.png' },
    ELF: { symbol: 'ELF', name: 'aelf', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2299.png' },
    FET: { symbol: 'FET', name: 'Fetch.ai', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3773.png' },
    IOTX: { symbol: 'IOTX', name: 'IoTeX', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2777.png' },
    MDX: { symbol: 'MDX', name: 'Mdex', imageUrl: 'https://assets.coingecko.com/coins/images/13775/large/mdex.png' },
    NEAR: { symbol: 'NEAR', name: 'NEAR Protocol', slug: slugNEAR, largeImage: 'https://assets.coingecko.com/coins/images/10365/large/near_icon.png', imageUrl: 'https://assets.coingecko.com/coins/images/10365/small/near_icon.png' },
    OCEAN: { symbol: 'OCEAN', name: 'Ocean Protocol', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3911.png' },
    ONT: { symbol: 'ONT', name: 'Ontology', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2566.png' },
    POLS: { symbol: 'POLS', name: 'Polkastarter', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7208.png' },
    REEF: { symbol: 'REEF', name: 'Reef', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6951.png' },
    WRX: { symbol: 'WRX', name: 'WazirX', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5161.png' },
    XEC: { symbol: 'XEC', name: 'eCash', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10791.png' },
    XVS: { symbol: 'XVS', name: 'Venus', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7288.png' },
    ZIL: { symbol: 'ZIL', name: 'Zilliqa', slug: slugZIL, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/2469.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2469.png' },
    CAKE: { symbol: 'CAKE', name: 'PancakeSwap', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7186.png' },
    ADX: { symbol: 'ADX', name: 'Ambire AdEx', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1768.png' },
    FIRO: { symbol: 'FIRO', name: 'Firo', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1414.png' },
    SXP: { symbol: 'SXP', name: 'Swipe', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4279.png' },
    IOTA: { symbol: 'IOTA', name: 'MIOTA', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1720.png' },
    AKRO: { symbol: 'AKRO', name: 'Akropolis', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4134.png' },
    AUDIO: { symbol: 'AUDIO', name: 'Audius', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7455.png' },
    BADGER: { symbol: 'BADGER', name: 'Badger DAO', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7859.png' },
    CVC: { symbol: 'CVC', name: 'Civic', imageUrl: 'https://assets.coingecko.com/coins/images/788/large/Civic-logo-blue.png' },
    DENT: { symbol: 'DENT', name: 'Dent', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1886.png' },
    DYDX: { symbol: 'DYDX', name: 'dYdX', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11156.png' },
    FORTH: { symbol: 'FORTH', name: 'Ampleforth Governance Token', imageUrl: 'https://assets.coingecko.com/coins/images/14917/large/photo_2021-04-22_00.00.03.jpeg' },
    GNO: { symbol: 'GNO', name: 'Gnosis', imageUrl: 'https://assets.coingecko.com/coins/images/662/large/logo_square_simple_300px.png' },
    HOT: { symbol: 'HOT', name: 'Holo', imageUrl: 'https://assets.coingecko.com/coins/images/3348/large/Holologo_Profile.png' },
    LRC: { symbol: 'LRC', name: 'Loopring', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1934.png' },
    NKN: { symbol: 'NKN', name: 'New Kind of Network', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2780.png' },
    NMR: { symbol: 'NMR', name: 'Numeraire', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1732.png' },
    NU: { symbol: 'NU', name: 'NuCypher', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4761.png' },
    OGN: { symbol: 'OGN', name: 'Origin Protocol', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5117.png' },
    POLY: { symbol: 'POLY', name: 'Polymath', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2496.png' },
    QNT: { symbol: 'QNT', name: 'Quant', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3155.png' },
    RLC: { symbol: 'RLC', name: 'iExec RLC', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1637.png' },
    RSR: { symbol: 'RSR', name: 'Reserve Rights', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3964.png' },
    SAND: { symbol: 'SAND', name: 'The Sandbox', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6210.png' },
    SKL: { symbol: 'SKL', name: 'SKALE Network', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5691.png' },
    SRM: { symbol: 'SRM', name: 'Serum', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6187.png' },
    TRB: { symbol: 'TRB', name: 'Tellor', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4944.png' },
    BIFI: { symbol: 'BIFI', name: 'Beefy Finance', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/7311.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7311.png' },
    TONIC: { symbol: 'TONIC', name: 'Tectonic', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/16430.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16430.png' },
    RLY: { symbol: 'RLY', name: 'Rally', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/8075.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8075.png' },
    SGB: { symbol: 'SGB', name: 'Songbird', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/12186.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12186.png' },
    KSM: { symbol: 'KSM', name: 'Kusama', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/5034.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5034.png' },
    APE: { symbol: 'APE', name: 'ApeCoin', slug: slugAPE, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/18876.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18876.png' },
    CPRX: { symbol: 'CPRX', name: 'Crypto Perx', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/13421.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/13421.png' },
    KAVA: { symbol: 'KAVA', name: 'Kava', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/4846.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4846.png' },
    XIDR: { symbol: 'XIDR', name: 'StraitsX Indonesian Rupiah', isStablecoin: true, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/15326.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15326.png' },
    XSGD: { symbol: 'XSGD', name: 'StraitsX Singapore Dollar', isStablecoin: true, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/8489.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8489.png' },
    SLP: { symbol: 'SLP', name: 'Smooth Love Potion', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/5824.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5824.png' },
    RON: { symbol: 'RON', name: 'Ronin', largeImage: 'https://assets.coingecko.com/coins/images/20009/large/ronin.jpg', imageUrl: 'https://assets.coingecko.com/coins/images/20009/small/ronin.jpg' },
    GALA: { symbol: 'GALA', name: 'Gala Games', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/7080.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7080.png' },
    RUNE: { symbol: 'RUNE', name: 'THORChain', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/4157.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4157.png' },
    CVX: { symbol: 'CVX', name: 'Convex Finance', largeImage: 'https://assets.coingecko.com/coins/images/15585/large/convex.png', imageUrl: 'https://assets.coingecko.com/coins/images/15585/small/convex.png' },
    RBN: { symbol: 'RBN', name: 'Ribbon Finance', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/12387.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12387.png' },
    TOKE: { symbol: 'TOKE', name: 'Tokemak', largeImage: 'https://assets.coingecko.com/coins/images/17495/large/tokemak-avatar-200px-black.png', imageUrl: 'https://assets.coingecko.com/coins/images/17495/small/tokemak-avatar-200px-black.png' },
    HBAR: { symbol: 'HBAR', name: 'Hedera', largeImage: 'https://assets.coingecko.com/coins/images/3688/large/hbar.png', imageUrl: 'https://assets.coingecko.com/coins/images/3688/small/hbar.png' },
    CHZ: { symbol: 'CHZ', name: 'Chiliz', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/4066.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4066.png' },
    USDD: { symbol: 'USDD', name: 'Decentralized USD', isStablecoin: true, largeImage: 'https://assets.coingecko.com/coins/images/25380/large/UUSD.jpg', imageUrl: 'https://assets.coingecko.com/coins/images/25380/small/UUSD.jpg' },
    GMX: { symbol: 'GMX', name: 'GMX', largeImage: 'https://assets.coingecko.com/coins/images/18323/large/arbit.png', imageUrl: 'https://assets.coingecko.com/coins/images/18323/small/arbit.png' },
    FBX: { symbol: 'FBX', name: 'Finblox Token', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/22341.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22341.png' },
    ARB: { symbol: 'ARB', name: 'Arbitrum', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/11841.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png' },
    PEPE: { symbol: 'PEPE', name: 'Pepe', largeImage: 'https://assets.coingecko.com/coins/images/29850/large/pepe-token.jpeg', imageUrl: 'https://assets.coingecko.com/coins/images/29850/small/pepe-token.jpeg' },
    FLOKI: { symbol: 'FLOKI', name: 'Floki', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/10804.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10804.png' },
    BOB: { symbol: 'BOB', name: 'BOB Token', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/24594.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/24594.png' },
    LADYS: { symbol: 'LADYS', name: 'Milady Meme Coin', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/25023.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/25023.png' },
    TBILL: { symbol: 'TBILL', name: 'T-Bills Token', largeImage: 'https://cms-media.finblox.com/tbill_d67757027d.svg', imageUrl: 'https://cms-media.finblox.com/tbill_d67757027d.svg' },
    BXC: { symbol: 'BXC', name: 'BitcoinX', largeImage: 'https://cms-media.finblox.com/bxc_323169a1f9.svg', imageUrl: 'https://cms-media.finblox.com/bxc_323169a1f9.svg' },
    OSMO: { symbol: 'OSMO', name: 'Osmosis', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/12220.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/12220.png' },
    PYUSD: { symbol: 'PYUSD', name: 'PayPal USD', isStablecoin: true, largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/27772.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/27772.png' },
    APT: { symbol: 'APT', name: 'Aptos', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/21794.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21794.png' },
    MEME: { symbol: 'MEME', name: 'Memecoin', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/28301.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/28301.png' },
    RLB: { symbol: 'RLB', name: 'Rollbit Coin', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/15271.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15271.png' },
    PUMLX: { symbol: 'PUMLX', name: 'PUML Better Health', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/21685.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/21685.png' },
    TIA: { symbol: 'TIA', name: 'Celestia', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/22861.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/22861.png' },
    ENA: { symbol: 'ENA', name: 'Ethena', largeImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/30171.png', imageUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/30171.png' },
};

export const slugToSymbolMap = {
    [slugBTC]: symbolToCurrencyMap.BTC,
    [slugETH]: symbolToCurrencyMap.ETH,
    [slugUSDT]: symbolToCurrencyMap.USDT,
    [slugUSDC]: symbolToCurrencyMap.USDC,
    [slugDAI]: symbolToCurrencyMap.DAI,
    [slugGUSD]: symbolToCurrencyMap.GUSD,
    [slugLTC]: symbolToCurrencyMap.LTC,
    [slugBCH]: symbolToCurrencyMap.BCH,
    [slugLINK]: symbolToCurrencyMap.LINK,
    [slugDOT]: symbolToCurrencyMap.DOT,
    [slugXLM]: symbolToCurrencyMap.XLM,
    [slugMATIC]: symbolToCurrencyMap.MATIC,
    [slugMANA]: symbolToCurrencyMap.MANA,
    [slugAAVE]: symbolToCurrencyMap.AAVE,
    [slugBAT]: symbolToCurrencyMap.BAT,
    [slugUNI]: symbolToCurrencyMap.UNI,
    [slugDOGE]: symbolToCurrencyMap.DOGE,
    [slugADA]: symbolToCurrencyMap.ADA,
    [slugEOS]: symbolToCurrencyMap.EOS,
    [slugXTZ]: symbolToCurrencyMap.XTZ,
    [slugGRT]: symbolToCurrencyMap.GRT,
    [slugCOMP]: symbolToCurrencyMap.COMP,
    [slugMKR]: symbolToCurrencyMap.MKR,
    [slugPAXG]: symbolToCurrencyMap.PAXG,
    [slugXRP]: symbolToCurrencyMap.XRP,
    [slugZRX]: symbolToCurrencyMap.ZRX,
    [slugBUSD]: symbolToCurrencyMap.BUSD,
    [slugLUNA]: symbolToCurrencyMap.LUNA,
    [slugSOL]: symbolToCurrencyMap.SOL,
    [slugSUSHI]: symbolToCurrencyMap.SUSHI,
    [slugZEC]: symbolToCurrencyMap.ZEC,
    [slugSNX]: symbolToCurrencyMap.SNX,
    [slug1INCH]: symbolToCurrencyMap['1INCH'],
    [slugTUSD]: symbolToCurrencyMap.TUSD,
    [slugUMA]: symbolToCurrencyMap.UMA,
    [slugKNC]: symbolToCurrencyMap.KNC,
    [slugAVAX]: symbolToCurrencyMap.AVAX,
    [slugOMG]: symbolToCurrencyMap.OMG,
    [slugSHIB]: symbolToCurrencyMap.SHIB,
    [slugBNB]: symbolToCurrencyMap.BNB,
    [slugETC]: symbolToCurrencyMap.ETC,
    [slugBNT]: symbolToCurrencyMap.BNT,
    [slugATOM]: symbolToCurrencyMap.ATOM,
    [slugOXT]: symbolToCurrencyMap.OXT,
    [slugFIL]: symbolToCurrencyMap.FIL,
    [slugFTM]: symbolToCurrencyMap.FTM,
    [slugAXS]: symbolToCurrencyMap.AXS,
    [slugUSDP]: symbolToCurrencyMap.USDP,
    [slugUST]: symbolToCurrencyMap.UST,
    [slugAPE]: symbolToCurrencyMap.APE,
    [slugNEAR]: symbolToCurrencyMap.NEAR,
    [slugCRV]: symbolToCurrencyMap.CRV,
    [slugYFI]: symbolToCurrencyMap.YFI,
    [slugALGO]: symbolToCurrencyMap.ALGO,
    [slugWBTC]: symbolToCurrencyMap.WBTC,
    [slugAMP]: symbolToCurrencyMap.AMP,
    [slugFLOW]: symbolToCurrencyMap.FLOW,
    [slugCELR]: symbolToCurrencyMap.CELR,
    [slugANKR]: symbolToCurrencyMap.ANKR,
    [slugBAL]: symbolToCurrencyMap.BAL,
    [slugTRX]: symbolToCurrencyMap.TRX,
    [slugEGLD]: symbolToCurrencyMap.EGLD,
    [slugENJ]: symbolToCurrencyMap.ENJ,
    [slugLPT]: symbolToCurrencyMap.LPT,
    [slugSTORJ]: symbolToCurrencyMap.STORJ,
    [slugINJ]: symbolToCurrencyMap.INJ,
    [slugUSDD]: symbolToCurrencyMap.USDD,
    [slugSLP]: symbolToCurrencyMap.SLP,
    [slugONE]: symbolToCurrencyMap.ONE,
    [slugZIL]: symbolToCurrencyMap.ZIL,
    [slugVET]: symbolToCurrencyMap.VET,
    [slugRON]: symbolToCurrencyMap.RON,
    [slugCRO]: symbolToCurrencyMap.CRO,
};

const BLOCKFI_BONUS_TEXT = `Sign up through this <a target="_blank" rel="noopener nofollow" href="https://blockfi.mxuy67.net/cefirates">promo link</a> and fund your account, to earn up to $250 in BTC. See the "Partner Offer" terms on this <a target="_blank" rel="noopener nofollow" href="https://blockfi.com/promotions/">page</a> for details.`;
const BLOCKFI_DISCLOSURE_HTML = `<p class="post-card-excerpt"><em>The BlockFi Interest Accounts (BIAs) have not been registered under the Securities Act of 1933 and may not be offered or sold in the  United States, to U.S. persons, for the account or benefit of a U.S. person or in any jurisdiction in which such offer would be prohibited.</em></p>`;
export const BLOCKFI_BONUS_HTML = `<p>${BLOCKFI_BONUS_TEXT}</p>${BLOCKFI_DISCLOSURE_HTML}`;

export const VOYAGER_BONUS_HTML = `
    <p>
        Sign up using <i>GAB05A7C6</i> in the 'Reward Code' field, then deposit and trade at least $100 to earn $50 in BTC. You'll need to sign up through their mobile app on <a target="_blank" rel="noopener nofollow" href="https://apps.apple.com/us/app/invest-voyager/id1396178579">iOS</a> or <a target="_blank" rel="noopener nofollow" href="https://play.google.com/store/apps/details?id=com.investvoyager">Android</a>. Promotion only available to US residents currently.
    </p>`;

const GEMINI_BONUS_TEXT = `Sign up through this <a target="_blank" rel="noopener nofollow" href="https://www.gemini.com/share/n7kxd8w39">referral link</a> or using referral code <i>n7kxd8w39</i>, then buy or sell $100 or more within 30 days of signing up. You'll receive $10 in BTC.`;
export const GEMINI_BONUS_HTML = `<p>${GEMINI_BONUS_TEXT}</p>`;

export const CELSIUS_BONUS_HTML = `
    <p>
        Sign up using the referral code <i>1854129a7a</i>, transfer in $400 or more, then hold for 30 days. You'll earn $50 in BTC. To sign up, follow this <a target="_blank" rel="noopener nofollow" href="https://celsiusnetwork.app.link/1854129a7a">link</a> and click on 'Sign Up', or sign up through their mobile app on <a target="_blank" rel="noopener nofollow" href="https://apps.apple.com/US/app/id1387885523?mt=8">iOS</a> or <a target="_blank" rel="noopener nofollow" href="https://play.google.com/store/apps/details?id=network.celsius.wallet">Android</a>.
    </p>`;

const HODLNAUT_BONUS_TEXT = `1) Sign up through this <a target="_blank" rel="noopener nofollow" href="https://www.hodlnaut.com/join/xZXkU6omx">referral link</a> or using referral code <i>xZXkU6omx</i>. 2) Make a deposit equivalent of US $1,000 or more, on any supported asset. This deposit must be in a single transaction and within 1 week of an optional test deposit . 3) Maintain a minimum $1,000 account balance for 31 consecutive days. 4) You'll receive $30 in USDC.`;
export const HODLNAUT_BONUS_HTML = `<p>${HODLNAUT_BONUS_TEXT}</p>`;

const LEDN_BONUS_TEXT = `Sign up through this <a target="_blank" rel="noopener nofollow" href="https://platform.ledn.io/join/43b2a3906f5ace8cfc0dc729a791fe5c">referral link</a>, and complete KYC. Then deposit at least 75 USDC or 0.0015 BTC, and hold for 15 days or longer. Deposit must be made within 30 days of opening an account. You'll receive 10 USDC.`;
export const LEDN_BONUS_HTML = `<p>${LEDN_BONUS_TEXT}</p>`;

export const CRYPTO_COM_BONUS_HTML = `
    <p>
        Sign up through this <a target="_blank" rel="noopener nofollow" href="https://crypto.com/app/6k4dx8gb6c">link</a> using referral code <i>6k4dx8gb6c</i>. Stake (deposit for 6 months) $400 in CRO for the ruby card tier or above, and you'll receive $25 in CRO.
    </p>`;

const NEXO_BONUS_TEXT = `Variable. Check <a target="_blank" rel="noopener nofollow" href="https://go.cefirates.com/nexo">Nexo</a> for any current promotions.`;
export const NEXO_BONUS_HTML = `<p>${NEXO_BONUS_TEXT}</p>`;

const VAULD_BONUS_TEXT = `Sign up through this <a target="_blank" rel="noopener nofollow" href="https://vauld.com/register/cefirates">referral link</a> or using referral code <i>cefirates</i> to earn lifetime bonuses on your activity. You'll receive a bonus of 2.5% of the interest you get paid on your assets, 2.5% of the interest you accrue on your loans, and 20% of the fees from your trades.`;
export const VAULD_BONUS_HTML = `<p>${VAULD_BONUS_TEXT}</p>`;

const HARU_BONUS_TEXT = `1) Sign up through this <a target="_blank" rel="noopener nofollow" href="https://webapp.haruinvest.com/signup?r=cefirates">link</a> or using referral code <i>cefirates</i>. 2) Create a new lockup of over $100, for at least 30 days (through Earn Plus or Earn Explore). 3) You'll earn a bonus of $10 in USDT. Only <a target="_blank" rel="noopener" href="/haru-invest-referral/#eligible-countries">certain countries</a> are eligible.`;
// const HARU_BONUS_TEXT = `Haru Invest has no sign up bonus currently. Feel free to sign up through this <a target="_blank" rel="noopener nofollow" href="https://webapp.haruinvest.com/signup?r=cefirates">link</a> or using referral code <i>cefirates</i>.`;
export const HARU_BONUS_HTML = `<p>${HARU_BONUS_TEXT}</p>`;

// const ABRA_BONUS_TEXT = `1) Sign up using this <a target="_blank" rel="noopener nofollow" href="https://www.abra.com/ref/?deep_link_sub1=RC7F4APFN">referral link</a> or referral code <i>RC7F4APFN</i>. 2) Complete KYC. 3) Deposit the equivalent of $15 or more, and hold for 7 days. 4) US users will earn $40 total in CPRX ($25 CPRX from the referal promo, as well as $15 CPRX from another current promo). *Non-US users must hold for 30 days and are only eligible to earn $5 CPRX.`;
const ABRA_BONUS_TEXT = `Abra has no sign up bonus currently. Feel free to sign up through this <a target="_blank" rel="noopener nofollow" href="https://www.abra.com/ref/?deep_link_sub1=RC7F4APFN">link</a> or using referral code <i>RC7F4APFN</i>.`;
export const ABRA_BONUS_HTML = `<p>${ABRA_BONUS_TEXT}</p>`;

const FINBLOX_BONUS_TEXT = `1) Sign up through this <a target="_blank" rel="noopener nofollow" href="https://join.finbloxapp.com/ref/9f33a12132">link</a>. 2) Pass the “Verified” level in Identity Verification. 3) Deposit assets within 7 days of signing up, and maintain balance for 30 days. 4) You'll earn a bonus of up to $250 USDC, based on the amount you deposit / maintain. See reward payout table <a target="_blank" rel="noopener" href="/finblox-referral/#reward-payout-structure">here</a>.`;
export const FINBLOX_BONUS_HTML = `<p>${FINBLOX_BONUS_TEXT}</p>`;

export const SIGN_UP_BONUS_HTML = `
    <h2 id="sign-up-bonuses">Sign Up Bonuses</h2>
    <p id="nexo-bonus">
        <strong>Nexo</strong> - ${NEXO_BONUS_TEXT}
    </p>
    <p id="crypto-com-bonus">
        <strong>Crypto.com App</strong> - Sign up through this <a target="_blank" rel="noopener nofollow" href="https://crypto.com/app/6k4dx8gb6c">link</a> using referral code <i>6k4dx8gb6c</i>. Stake (deposit for 6 months) $400 in CRO for the ruby card tier or above, and you'll receive $25 in CRO.
    </p>
    <p id="finblox-bonus">
        <strong>Finblox</strong> - ${FINBLOX_BONUS_TEXT}
    </p>
    <p id="ledn-bonus">
        <strong>Ledn</strong> - ${LEDN_BONUS_TEXT}
    </p>`;
