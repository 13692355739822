import { NEXO_BONUS_HTML } from '../utils/constants';
import { ratesInt as newInt, nexoDateEffective } from './nexo-rates/4-27-24';
import { ratesInt as oldInt } from './nexo-rates/2-7-24';

import { ratesUS as newUS, nexoDateEffectiveUS } from './nexo-rates/4-1-23';
import { ratesUS as oldUS } from './nexo-rates/12-15-22';

const availabilityInfoUS = 'Nexo is no longer available for earning interest in the United States';
export const nexoCantWithdraw = 'cannot be deposited or withdrawn, only bought or sold through Nexo';

export const nexoRowInfoInt = {
    name: 'Nexo',
    withdrawalFees: 'free',
    additionalInfo: '-',
    icon: '/images/nexo-logo.svg',
    order: 0,
    bonus: 'variable',
    bonusLink: 'nexo-bonus',
    referralSlug: 'nexo-referral',
};

export const nexoRates = {
    oldUS,
    oldInt,
    newUS,
    newInt,
    platform: 'Nexo',
    bonusHTML: NEXO_BONUS_HTML,
    dateEffectiveUS: nexoDateEffectiveUS,
    dateEffectiveInt: nexoDateEffective,
    availabilityInfoUS,
    key: 'nexo',
};
