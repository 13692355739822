import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import {RatesByPlatformNav, RatesByCurrencyNav, MobileNavSheet, Link, RatesVsRatesNav, HighestRatesNav, SponsoredBar, GoToTop, WarningBar } from '.'
import config from '../../utils/siteConfig'

// Styles
import '../../styles/app.css'

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const DefaultLayout = ({ data, children, bodyClass, isHome, location }) => {
    const [hideLoading, updateHideLoading] = useState(true);
    useEffect(() => {
        updateHideLoading(false);
    }, []);
    const site = data.allGhostSettings.edges[0].node
    const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
    const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null

    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`svg { width: 16px; } ${site.codeinjection_styles}`}</style>
                <body className={bodyClass} />
            </Helmet>

            <div className="viewport">

                <div className="viewport-top">
                    {/* The main header section on top of the screen */}
                    {/* <SponsoredBar /> */}
                    {/* <WarningBar /> */}
                    <header className="site-head" style={{ ...site.cover_image && { backgroundImage: `url(${site.cover_image})` } }}>
                        <div className="container">
                            <div className="site-mast">
                                <div className="site-mast-left">
                                    {/* <Link to={`/`}>
                                        {site.logo ?
                                            <img className="site-logo" src={site.logo} alt={site.title} />
                                            : <Img fixed={data.file.childImageSharp.fixed} alt={site.title} />
                                        }
                                    </Link> */}
                                    <Link className="site-nav-item logo-text" to="/">CeFi Rates</Link>
                                    {/* <Navigation data={site.navigation} navClass="site-nav-item" /> */}
                                </div>
                                    {!hideLoading && (
                                        <div className="site-mast-right">
                                            <MobileNavSheet location={location} />
                                            <div className="desktop-nav">
                                                <RatesByCurrencyNav />
                                                <RatesByPlatformNav />
                                                <RatesVsRatesNav />
                                                <HighestRatesNav />
                                            </div>
                                            {/* { site.facebook && <a href={ facebookUrl } className="site-nav-item" target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/facebook.svg" alt="Facebook" /></a>} */}
                                            {/* <a className="site-nav-item" href={ `https://feedly.com/i/subscription/feed/${config.siteUrl}/rss/` } target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/rss.svg" alt="RSS Feed" /></a> */}
                                        </div>
                                    )}
                            </div>
                            {/* <nav className="site-nav">
                                <div className="site-nav-left">
                                    <Navigation data={site.navigation} navClass="site-nav-item" />
                                </div>
                                <div className="site-nav-right">
                                    <Link className="site-nav-button" to="/about">About</Link>
                                </div>
                            </nav> */}
                            { isHome ?
                                <div className="site-banner">
                                    {/* <h1 className="site-banner-title">{site.title}</h1>
                                    <p className="site-banner-desc">{site.description}</p> */}
                                    {/* <h1 className="site-banner-title">CeFi Rates</h1>
                                    <p className="site-banner-desc">Compare the best crypto interest accounts</p> */}
                                    {/* <h1 className="site-banner-title">Compare the best crypto</h1>
                                    <h1 className="site-banner-title">interest accounts</h1> */}
                                    <h1 className="site-banner-title">Compare the Best Crypto <br/>Interest Accounts</h1>
                                    <p className="site-banner-desc">Compare interest rates, withdrawal fees, and other factors, across the top CeFi platforms. Find the best place to earn on your crypto.</p>
                                </div> :
                                null}
                        </div>
                    </header>

                    <main className="site-main">
                        {/* All the main content gets inserted here, index.js, post.js */}
                        {children}
                    </main>
                    <GoToTop />
                </div>

                <div className="viewport-bottom">
                    {/* The footer at the very bottom of the screen */}
                    <footer className="site-foot">
                        {/* <p className="container">
                        Some content may contain affiliate links, meaning that for referring you, we may receive a commission. This is at no cost to you, and may even provide you with a bonus / discount as part of the referral offer.
                        </p> */}
                        {/* <p className="container">Don't Trust, Verify</p> */}
                        <div className="site-foot-nav container">
                            <div className="site-foot-nav-left">
                                <div>Don't Trust, Verify</div>
                                <div>This content is for general informational purposes only and is not investment advice.</div>
                                {/* <div>While we try to keep the information on this site up to date and accurate, there many be errors or inaccuracies. If you notice any issues, please <a href="mailto:hello@cefirates.com">let us know</a>.</div> */}
                                <div>While we try to keep the information up to date and accurate, we make no warranties to its accuracy or completeness.</div>
                                <div>If you notice any issues, please <a href="mailto:hello@cefirates.com">let us know</a>.</div>
                                <div>Another useful place to <a title="compare crypto interest rates" href="https://www.coininterestrate.com" target="_blank" rel="noopener">compare crypto interest rates</a></div>
                                <Link to="/">CeFi Rates</Link> © 2024
                            </div>
                            <div className="site-foot-nav-right">
                                <div>
                                    {/* <a href="mailto:hello@cefirates.com" onClick={() => olark('api.box.expand')}>Contact Us</a> */}
                                    <a href="mailto:hello@cefirates.com">Contact Us</a>
                                </div>
                                <div>
                                    <Link to="/referrals/">Referrals / Sign Up Bonuses</Link>
                                </div>
                                <div>
                                    <Link to="/withdrawal-fees/">Withdrawal Fees</Link>
                                </div>
                                <div>
                                    <Link to="/developer-activity/">Blockchain Developer Activity</Link>
                                </div>
                                <div>
                                    <Link to="/best-hardware-wallets/">Best Hardware / Cold Wallets</Link>
                                </div>
                                { site.twitter && <a href={ twitterUrl } className="social-icon" target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/twitter.svg" alt="Twitter" /> &nbsp;Twitter</a>}
                                {/* <Navigation data={site.secondary_navigation} navClass="site-foot-nav-item" /> */}
                            </div>
                        </div>
                    </footer>

                </div>
            </div>

        </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

const DefaultLayoutSettingsQuery = props => {
    return (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "ghost-icon.png"}) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)}

export default DefaultLayoutSettingsQuery
